import React from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import TermsAndConditions from './containers/TermsAndConditions'
import Login from './containers/Login'
import Persons from './containers/PersonsList'
import UserProfile from './containers/UserProfile'
import SlimReport from './Report/Slim'
import PersonDetails from './Person/Details'
import PersonAddForm from './Person/AddForm'
import Home from './containers/Home'

const Router = props => (
    <HashRouter>
        <Switch>
            <Route exact path={'/login'} component={Login}/>
            <Route exact path={'/persons'} component={Persons}/>
            <Route exact path={'/profile'} component={UserProfile}/>
            <Route exact path={'/slim-analysis/:id'} component={SlimReport}/>
            <Route exact path={'/terms-and-conditions'} component={TermsAndConditions}/>
            <Route path={'/person/add'} component={PersonAddForm}/>
            <Route path={'/person/:id/update'} component={PersonAddForm}/>
            <Route path={'/person/:id'} component={PersonDetails}/>
            <Route exact path={'/'} component={Home}/>
        </Switch>
    </HashRouter>
);

export default Router
