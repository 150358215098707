import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './Layout/Main';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import configureStore from './store/configureStore';
import {Provider} from "react-redux";

import "./i18n";

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter><Main/></BrowserRouter>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
