import React from 'react';
import Container from 'react-bootstrap/Container';
import '../Layout/Footer.css'
import Row from "react-bootstrap/Row";

const Footer = props => (
    <Container>
        <Row>
            This is a hobby application. We don't get any revenue from any third party service and
            we don't collect any anonymous data.
            Your email is only used to identify the data that you entered.
            You will get no emails from this application.

            Code can be viewed on request.

            For any questions or requests, please contact me: cristian.cotoi@gmail.com
        </Row>
    </Container>
);

export default Footer;
