import React from 'react';
import './List.css'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom'

const Person = ({details}) => (
    <li>
        <Row>
            <Col>
                {details.name} {details.surname}
            </Col>
            <Col>
                <ButtonGroup size="sm" className="mr-2">
                    <Link to={`/slim-analysis/${details.id}`}>
                        <Button size="sm" variant="success">
                            Report
                        </Button>
                    </Link>
                </ButtonGroup>
                <ButtonGroup size="sm" className="mr-2">
                    <Link to={`/person/${details.id}`}>
                        <Button size="sm" variant="info">
                            Details
                        </Button>
                    </Link>
                </ButtonGroup>
            </Col>
        </Row>
    </li>
);

const ExtraLine = ({extraPersonsSize}) => (
    <li>
        ... {extraPersonsSize} more items
    </li>
);

const PersonsList = props => {
    return (
        <div id="app-persons-list" className="App-Persons-List">
            <ul className="persons-list">
                {
                    props.list
                        .map(person => (
                                <Person details={person} key={person.id}/>
                            )
                        )
                        .slice(0, 15)
                }
                {
                    props.list.length > 15 &&
                    <ExtraLine extraPersonsSize={props.list.length - 15}/>
                }
            </ul>
        </div>
    );
};

export {PersonsList, Person, ExtraLine};
