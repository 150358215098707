import React from 'react';
import Card from "react-bootstrap/Card";
import './index.css'
import Service from "../lib/Service";

class SlimReport extends React.Component {
    state = {
        report: null,
        personId: null,
        isFetching: false
    };

    componentDidMount() {
        console.log('Slim report mounted');
        const id = this.props.match.params.id;
        this.setState({isFetching: true, personId: this.props.match.params.id});
        const params = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };
        fetch(`${Service.server}/api/core/${id}`, params)
            .then(response => {
                console.log('Report request successful');
                return response.json();
            }, console.error)
            .then(report => {
                this.setState({report: report, isFetching: false});
            });
    }

    /**Render everything in one go*/
    bulkRender(report) {
        const groupedAspects = Object.values(report).reduce(function (acc, reportItem) {
            const key = reportItem['aspect'];
            if (key !== undefined)
                (acc[key] = acc[key] || []).push(reportItem);
            return acc;
        }, {});

        return <>
            {Object
                .keys(groupedAspects)
                .map((key) => {
                    const listOfAspects = groupedAspects[key];
                    if (key === 'general') return <div key={key}/>;
                    return (<div key={key}>
                        <Card>
                            <Card.Title>{key}</Card.Title>
                        </Card>
                        {Object
                            .values(listOfAspects)
                            .filter(
                                item => {
                                    const mainAspects = [
                                        'general'
                                    ];
                                    return !mainAspects.includes(item['aspect']) && item.id !== undefined;
                                })
                            .map(aspect => {
                                const uniqueId = `${aspect.id}-${aspect.description.substring(0, 5)}`;
                                return (
                                    <Card key={uniqueId}>
                                        <Card.Body>{aspect.description}</Card.Body>
                                    </Card>
                                );
                            })
                        }
                    </div>);
                })
            }
        </>;
    }

    /**Group items by importance*/
    basicRender(report) {
        return (
            <>
                <Card>
                    <Card.Body>{report.dm.description}</Card.Body>
                    <Card.Body>{report.destiny.description}</Card.Body>
                </Card>
                <Card>
                    <Card.Body>{report.normalLifeType.description}</Card.Body>
                </Card>
                <Card>
                    <Card.Body>{report['internal vibration'].description}</Card.Body>
                    <Card.Body>{report['external vibration'].description}</Card.Body>
                    <Card.Body>{report['cosmic vibration'].description}</Card.Body>
                </Card>
            </>
        )
    }

    render() {
        const {report, isLoading} = this.state;
        if (isLoading || report === null) {
            return (<div>Loading...</div>);
        }

        return (
            <>
                <Card>
                    <Card.Title>Who is {report.surname} {report.name}?</Card.Title>
                </Card>
                {this.basicRender(report)}
                {this.bulkRender(report)}
            </>
        );
    }
}

export default SlimReport;
