import React, {Component} from 'react'
import Loader from '../components/Loader'
import PersonName from './Name'
import {DateDetails, ExtraDetails, TimeDetails} from "./DateDetails";
import Service from '../lib/Service'
import Button from "react-bootstrap/Button";
import {Link, Redirect} from 'react-router-dom'

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import './Details.css'
import Modal from "react-bootstrap/Modal";
import {withTranslation} from "react-i18next";

class PersonDetails extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
        this.handleDeleteModalConfirm = this.handleDeleteModalConfirm.bind(this);

        this.state = {
            person: null,
            isFetchingDetails: false,
            baZiChart: {},
            baZiBaseElements: {},
            isFetchingBaZi: false,

            pSquare: {},
            isFetchingPs: false,

            returnToPersonList: false
        };
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        this
            .fetchAll(id)
            .then(() => {
                console.info('All info has been retrieved');
            })
            .catch(err => {
                console.error('Info retrieval failed');
                console.error(err);
            });
    }

    handleDelete(e) {
        e.preventDefault();
        this.setState({showDeleteModal: true})
    }

    handleDeleteModalClose(e) {
        e.preventDefault();
        this.setState({showDeleteModal: false});
    }

    handleDeleteModalConfirm(e) {
        e.preventDefault();
        this.setState({showDeleteModal: false});
        Service.deletePerson(this.state.person.id)
            .then(pSquare => {
                this.setState({
                    pSquare: pSquare,
                    isFetchingPs: false,
                    returnToPersonList: true
                });
            });
    }

    async fetchAll(id) {
        this.setState({
            isFetchingDetails: true,
            isFetchingBaZi: true,
            isFetchingPs: true
        });
        const person = await Service.personDetails(id);
        this.setState({person: person, isFetchingDetails: false});

        if (person === null || person === undefined) return;
        const lang = this.props.i18n.language;
        Service.baZiBaseElements()
            .then(baZiBaseElements => {
                this.setState({baZiBaseElements: baZiBaseElements})
            });
        Service.baZiChartForDate(person.date, lang)
            .then(baZiChart => {
                this.setState({baZiChart: baZiChart, isFetchingBaZi: false})
            });
        Service.numerologyChartForDate(person.date)
            .then(pSquare => {
                this.setState({pSquare: pSquare, isFetchingPs: false})
            });
    }

    render() {
        const {
            person, isFetchingDetails,
            returnToPersonList
        } = this.state;

        if (returnToPersonList)
            return <Redirect to='/persons'/>;

        return (
            <div className="person">
                <Row>
                    <Link to={`/persons`}>
                        <Button
                            size='md'
                            variant={'primary'}>
                            Back to persons list
                        </Button>
                    </Link>
                </Row>
                {!isFetchingDetails && person &&
                <Row>
                    <Col>
                        <PersonName
                            name={this.state.person.name}
                            surname={this.state.person.surname}
                        />
                        <DateDetails date={this.state.person.date}/>
                        <TimeDetails person={this.state.person}/>
                        <ExtraDetails person={this.state.person}/>
                    </Col>
                    <Col>
                        <Row>
                            <Link to={`/person/${person.id}/update`}>
                                <Button
                                    size='md'
                                    variant={'primary'}>
                                    Modify
                                </Button>
                            </Link>
                        </Row>
                        <Row>
                            <Button
                                onClick={this.handleDelete}
                                size='md'
                                variant={'danger'}>
                                Delete
                            </Button>
                        </Row>
                    </Col>
                </Row>
                }
                {!isFetchingDetails && person &&
                <Row>
                    <Col>
                        <Link to={`/person/${person.id}/ps`}>
                            <Button
                                size='md'
                                variant={'success'}>
                                Report
                            </Button>
                        </Link>
                    </Col>
                </Row>
                }
                <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete person?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>This action cannot be undone. Are you sure?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleDeleteModalClose}>
                            Close
                        </Button>
                        <Button variant="danger" onClick={this.handleDeleteModalConfirm}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                {!isFetchingDetails && person === null &&
                <p>Unable to find person with given id</p>
                }
                {isFetchingDetails && person !== null &&
                <Loader/>
                }
            </div>
        );
    }
}

export default withTranslation()(PersonDetails);
