import React, {Component} from 'react'

import GoogleAuthButton from '../components/Auth/Google/GoogleAuth';

class Login extends Component {
    componentDidMount() {
    }

    render() {
        return (
            <div className="login">
                <h4>Login</h4>
                <GoogleAuthButton/>
            </div>
        );
    }
}

export default Login;
