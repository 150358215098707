import * as types from './actionTypes';

export function setUser(user) {
    return dispatch => {
        console.info(`Set user ${user}`);
        return dispatch({
            type: types.LOGIN,
            user: user
        });
    };
}

export function unsetUser() {
    return dispatch => {
        console.info('Unset user');
        return dispatch({
            type: types.LOGOUT,
            user: null
        });
    };
}
