import React, {Component} from 'react'

import './Home.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Jumbotron from "react-bootstrap/Jumbotron";
import {Link} from "react-router-dom";

class Login extends Component {
    state = {
        isLoggedIn: false
    };

    componentDidMount() {
        this.setState({
            isLoggedIn: localStorage.getItem('user') !== null
        })
    }

    render() {
        const {isLoggedIn} = this.state;

        return (
            <div className="home">

                <Container>
                    <Row className='jumbo centered' size='xxl'>
                        <Col xs={12}>
                            <Jumbotron fluid>
                                <h1>Little Eye</h1>
                                <p>
                                    I spy with my little eye, a good professional :)
                                </p>
                            </Jumbotron>
                        </Col>
                    </Row>
                </Container>
                {!isLoggedIn &&
                <Container>
                    <Row className='jumbo centered'>
                        <Col xs={12} md={6}>
                            <h2 className='centered'>Members</h2>
                            <p className='centered'> Create an account to enjoy access to all
                                functionalities like view of the daily and monthly Bazi potential of
                                the day and ability to create and save people's Bazi and
                                Numerolagical analysis with all the insites</p>
                            <div className='centered'>
                                <Link className='dropdown-item'
                                      to={'/login'}>
                                    <Button size='lg' variant='dark'>Login</Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                }
            </div>
        );
    }
}

export default Login;
