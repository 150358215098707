import {LOGIN, LOGOUT} from '../actions/actionTypes';

const initialState = {
    user: localStorage.getItem('user')
};

export default function loginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                user: action.user
            };
        case LOGOUT:
            return {
                ...state,
                user: null
            };
        default:
            return state === undefined ? '' : state;
    }
}