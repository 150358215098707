import React from 'react';
import Container from 'react-bootstrap/Container';
import './Footer.css'
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import {withTranslation} from "react-i18next";

const Footer = props => {
    const t = props.t;

    return (
        <Container className='footer'>
            <Navbar bg="light" expand="lg">
                <Navbar.Collapse id="basic-navbar-nav" className='tall-footer'>
                    <Nav className='mr-auto copyright'>
                        &copy; Cristian Coțoi
                    </Nav>
                    <Nav>
                        <Link className='nav-link'
                              to={'/terms-and-conditions'}>{t('layout:Terms and conditions')}
                        </Link>
                        <Link className='nav-link'
                              to={'/contact'}>{t('layout:Contact')}
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    );
};

export default withTranslation()(Footer);
