import React, {Component} from 'react';
import './Main.css';
import Footer from './Footer';
import Routes from '../Routes'
import Header from '../Layout/Header'
import 'whatwg-fetch'
import {HashRouter, Route, Switch} from 'react-router-dom';


class Main extends Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route path={'/'} component={
                        () => (
                            <div className="App container">
                                <Header/>
                                <Routes/>
                                <Footer/>
                            </div>
                        )
                    }/>
                </Switch>
            </HashRouter>
        );
    }
}

export default Main;
