import React, {Component} from 'react';
import {GoogleLogin, GoogleLogout} from 'react-google-login';
import {Redirect} from 'react-router-dom'
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import * as loginActions from '../../../actions/loginActions';

class GoogleAuthButtons extends Component {
    state = {
        isLoggedIn: false,
        redirect: false
    };

    componentDidMount() {
        this.setState({
            isLoggedIn: localStorage.getItem('user') !== null
        })
    }

    loginSuccess(currentObj) {
        return (response) => {
            localStorage.setItem('user', response.profileObj.email);
            localStorage.setItem('login-source', 'google oauth');

            this.props.setUser(response.profileObj.email);

            currentObj.setState({isLoggedIn: true, redirect: true});
        };
    }

    logout(currentObj) {
        return (response) => {
            localStorage.removeItem('user');
            localStorage.removeItem('login-source');

            this.props.unsetUser();

            currentObj.setState({isLoggedIn: false, redirect: true});
        };
    }

    render() {
        const {isLoggedIn} = this.state;

        if (this.state.redirect) {
            return <Redirect to='/'/>
        }

        return <div>
            {
                !isLoggedIn &&
                <GoogleLogin
                    clientId="128085026282-s50k6s900aa2lus5s6ah6gekm5qrrbgt.apps.googleusercontent.com"
                    buttonText="Login"
                    onSuccess={this.loginSuccess(this)}
                    onFailure={response => console.error(response)}
                    cookiePolicy={'single_host_origin'}
                    scope='email'
                />
            }
            {
                isLoggedIn &&
                <GoogleLogout
                    buttonText="Logout"
                    clientId="128085026282-s50k6s900aa2lus5s6ah6gekm5qrrbgt.apps.googleusercontent.com"
                    onLogoutSuccess={this.logout(this)}
                >
                </GoogleLogout>
            }
        </div>;
    }
}


GoogleAuthButtons.propTypes = {
    loginActions: PropTypes.object,
    user: PropTypes.string
};

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => dispatch(loginActions.setUser(user)),
        unsetUser: () => dispatch(loginActions.unsetUser())
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GoogleAuthButtons);