class Service {
    static server = `https://little-eye-server.herokuapp.com`;
    // static server = `http://localhost:8080`;

    static personDetails(id) {
        const params = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };
        return fetch(`${this.server}/api/persons/${id}`, params)
            .then(response => {
                console.log(`Person with id ${id} request successful`);
                return response.json();
            })
            .catch(error => {
                console.error(error);
                return {}
            });
    }

    static deletePerson(id) {
        const params = {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'}
        };
        return fetch(`${this.server}/api/persons/${id}`, params)
            .then(response => {
                console.info(`Person with id ${id} delete successful`);
                return response.json();
            })
            .catch(error => {
                console.error(error);
                return {}
            });
    }

    static updateUserLang(userId, lang) {
        const reqBody = {
            command: 'update lang',
            lang: lang
        };
        const params = {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(reqBody)
        };
        return fetch(`${this.server}/api/users/${userId}`, params)
            .then(response => {
                console.info(`Language updated`);
                return response.text();
            })
            .catch(error => {
                console.error(error);
                return {}
            });
    }

    static baZiChartForDate(date, lang, extraRulesArray) {
        const reqBody = {
            params: {
                person: {date: date},
                rules: extraRulesArray || [],
                lang: lang
            }
        };
        const params = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(reqBody)
        };
        return fetch(`${this.server}/api/bazi`, params)
            .then(response => {
                console.log(`BaZi chart request successful`);
                return response.json();
            })
            .catch(error => {
                console.error(error);
                return {};
            });
    }

    static numerologyChartForDate(date, lang, extraRulesArray) {
        const reqBody = {
            params: {
                person: {date: date}, rules: extraRulesArray || []
            }
        };
        const params = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(reqBody)
        };
        return fetch(`${this.server}/api/psquare`, params)
            .then(response => {
                console.log(`BaZi chart request successful`);
                return response.json();
            })
            .catch(error => {
                console.error(error);
                return {};
            });
    }

    static baZiBaseElements() {
        const params = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };
        return fetch(`${this.server}/api/bazi/base-elements`, params)
            .then(response => {
                console.log(`BaZi base elements request successful`);
                return response.json();
            })
            .catch(error => {
                console.error(error);
                return {};
            });
    }
}

export default Service;
